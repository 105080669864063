import { getFeatureFlag } from '@pulselive/launchdarkly-sdk-wrapper';
import AudienceIcon from '../shared-components/icons/AudienceIcon';
import DataCaptureIcon from '../shared-components/icons/DataCaptureIcon';
import PredictorIcon from '../shared-components/icons/PredictorIcon';
import PredictionsIcon from '../shared-components/icons/PredictionsIcon';
import TriviaIcon from '../shared-components/icons/TriviaIcon';

export const getRoutes = () => {
    const routes = [
        {
            type: 'route',
            path: '/',
            routes: [],
            props: {
                text: 'Home'
            }
        }
    ];

    const pollingRoute = {
        type: 'route',
        path: '/polls',
        routes: [
            {
                type: 'application',
                name: '@pulselive/fek-polling-microfrontend'
            }
        ],
        props: {
            text: 'Polling',
            description: 'Create and manage polls',
            icon: AudienceIcon
        }
    };

    const triviaRoute = {
        type: 'route',
        path: '/trivia',
        routes: [
            {
                type: 'application',
                name: '@pulselive/fek-polling-microfrontend'
            }
        ],
        props: {
            text: 'Trivia',
            description: 'Test the knowledge of your fans',
            icon: TriviaIcon
        }
    };

    const predictorRoute = {
        type: 'route',
        path: '/predictor',
        routes: [
            {
                type: 'application',
                name: '@pulselive/fek-predictor-microfrontend'
            }
        ],
        props: {
            text: 'Predictor',
            description: 'Predictor Game',
            icon: PredictorIcon
        }
    };

    const dataCaptureRoute = {
        type: 'route',
        path: '/data-capture',
        routes: [
            {
                type: 'application',
                name: '@pulselive/fek-data-capture-microfrontend'
            }
        ],
        props: {
            text: 'Data Capture',
            description: 'Various Forms for capturing a users data',
            icon: DataCaptureIcon
        }
    };

    const guessTheGameweekRoute = {
        type: 'route',
        path: '/pl-match-predictor',
        routes: [
            {
                type: 'application',
                name: '@pulselive/playbook-guess-the-gameweek-microfrontend'
            }
        ],
        props: {
            text: 'Premier League Match Predictor',
            description: 'Create and manage match predictors',
            icon: PredictorIcon
        }
    };

    const predictionsRoute = {
        type: 'route',
        path: '/predictions',
        routes: [
            {
                type: 'application',
                name: '@pulselive/fek-predictor-microfrontend'
            }
        ],
        props: {
            text: 'Predictors',
            description: 'Fans predict the outcome of events',
            icon: PredictionsIcon
        }
    };

    const tournamentPredictorRoute = {
        type: 'route',
        path: '/tournament-predictor',
        routes: [
            {
                type: 'application',
                name: '@pulselive/playbook-tournament-predictor-microfrontend'
            }
        ],
        props: {
            text: 'Tournament Predictor',
            description: 'Fans play a tournament predictor game',
            icon: PredictionsIcon
        }
    };

    const settingsRoute = {
        type: 'route',
        path: '/settings',
        routes: [
            {
                type: 'application',
                name: '@pulselive/playbook-settings-microfrontend'
            }
        ],
        props: {
            text: 'Settings',
            description: 'Account-wide settings (SSO)',
            icon: PredictionsIcon
        }
    };

    const quizzesRoute = {
        type: 'route',
        path: '/quizzes',
        routes: [
            {
                type: 'application',
                name: '@pulselive/playbook-quizzes-microfrontend'
            }
        ],
        props: {
            text: 'Quizzes',
            description: 'Create and manage quizzes',
            icon: TriviaIcon
        }
    };

    if (getFeatureFlag('product-polling')) {
        routes.push(pollingRoute);
    }

    if (getFeatureFlag('product-predictions')) {
        routes.push(predictionsRoute);
    }

    if (getFeatureFlag('product-predictor') && getFeatureFlag('predictor')) {
        routes.push(predictorRoute);
    }

    if (
        getFeatureFlag('product-data-capture') &&
        getFeatureFlag('data-capture-product')
    ) {
        routes.push(dataCaptureRoute);
    }

    if (getFeatureFlag('product-trivia') && getFeatureFlag('trivia-product')) {
        routes.push(triviaRoute);
    }

    if (
        getFeatureFlag('product-guess-the-gameweek') &&
        getFeatureFlag('ff-guess-the-gameweek')
    ) {
        routes.push(guessTheGameweekRoute);
    }

    if (getFeatureFlag('product-tournament-predictor')) {
        routes.push(tournamentPredictorRoute);
    }

    if (getFeatureFlag('product-settings')) {
        routes.push(settingsRoute);
    }

    if (getFeatureFlag('product-quizzes')) {
        routes.push(quizzesRoute);
    }

    routes[0].routes.push({
        type: 'application',
        name: '@pulselive/homepage',
        props: { applicationRoutes: homeTilesData(routes) }
    });

    return routes;
};

export const navItemsData = (customRoutes) =>
    customRoutes.map((route) => {
        return {
            path: route.path,
            text: route.props.text,
            createBtn: { ...route.props.createBtn }
        };
    });

const homeTilesData = (customRoutes) =>
    customRoutes.map((route) => {
        return {
            path: route.path,
            name: route.props.text,
            description: route.props.description,
            Icon: route.props.icon
        };
    });
